

import {fetchPost} from '../utils/axios'


export function serviceState(params) {
  return fetchPost('sanxingdui-sso-1.0/v1/sxd_get_start_service_state', params);
}
export function login(params) {
  return fetchPost('sanxingdui-sso-1.0/v1/sxd_login_redis', params);
}
export function answer(params) {
  return fetchPost('sanxingdui-sso-1.0/v1/sxd_verify_answer', params);
}
export function getQuestion(params) {
  return fetchPost('sanxingdui-sso-1.0/v1/sxd_get_question', params);
}
export function setQuestion(params) {
  return fetchPost('sanxingdui-sso-1.0/v1/sxd_set_question', params);
}
export function getData(params) {
  return fetchPost('sanxingdui-sso-1.0/v1/sxd_get_payload_status_redis', params);
}
export function getGcs(params) {
  return fetchPost('sanxingdui-sso-1.0/v1/sxd_get_init_paras_redis', params);
}


