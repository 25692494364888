<template>
    <div class="login" :class="loginPc?'loginPc':''">
        <van-notice-bar
                left-icon="volume-o"
                text="中央广播电视总台大型沉浸式数字交互空间《三星堆奇幻之旅》内测服务器，登录体验请注意手机流量消耗。"
                @click="showNotic"
        />
        <div class="login-body" v-if="serviceStateShow">
            <van-form @submit="onSubmit">
                <div class="inputBox">
                    <van-field
                            v-model="username"
                            name="username"
                            class="loginField phone"
                            label=""
                            placeholder="请输入手机号"
                    />
                </div>

                <div class="inputBox">
                    <div class="codeImg">
                        <vue-img-verify ref="verifyRef" v-if="showCode"/>
                    </div>
                    <img src="https://sxd.kk.aliyun.com/img/refresh.png" alt="" class="refreshImg" @click="refreshImg">

                    <van-field
                            center
                            clearable
                            label=""
                            placeholder="验证码"
                            class="loginField code"
                            v-model="verify"
                    >
                        <!--          <template #button>-->
                        <!--            -->
                        <!--          </template>-->
                    </van-field>
                </div>
                <div class="inputBox">
                    <!--            <van-button class="loginBtn" round block  native-type="submit">登录</van-button>-->

                    <div class="loginBtn" @click="onSubmit">登&nbsp;&nbsp;&nbsp;&nbsp;录</div>
<!--                    <div @click="full">  全屏</div>-->
                </div>
            </van-form>
        </div>
<!--        <div class="login-body" v-if="!serviceStateShow">-->
<!--            <div class="loginMaintain"></div>-->
<!--        </div>-->
        <div class="logoBg"></div>

        <van-dialog class="loginDialog" v-model:show="show" :confirmButtonText="'知道了'" title="《三星堆奇幻之旅》公告"  round-button>
            <p class="dialogP">中央广播电视总台大型沉浸式数字交互空间《三星堆奇幻之旅》内测服务器，登录体验请注意手机流量消耗。</p>
        </van-dialog>
    </div>
</template>

<script>
    import {onMounted, reactive, ref, toRefs} from 'vue'
    import vueImgVerify from '@/components/VueImageVerify'
    import {login,serviceState} from '@/service/user'
    import {setLocal} from '@/common/js/utils'
    import {Toast} from 'vant'
    import {useRouter} from "vue-router";

    export default {
        setup() {
            const router = useRouter();
            const verifyRef = ref(null);
            let show = ref(false);
            const state = reactive({
                username: '',
                verify: '',
                imgCode: "",
                showCode: true,
                serviceStateShow:false,
                loginPc:false
            })
            //刷新图片
            const refreshImg = () => {
                state.showCode = false;
                Toast.loading({
                    message: '加载中...',
                    forbidClick: true,
                })
                setTimeout(function () {
                    state.showCode = true;
                    Toast.clear()
                }, 1000)

            }
            // 提交登录或注册表单
            const onSubmit = async () => {
                if (state.verify == '') {
                    Toast("验证码不能为空");
                    return;
                }
                state.imgCode = verifyRef.value.imgCode || ''
                if (state.verify.toLowerCase() != state.imgCode.toLowerCase()) {
                    Toast('验证码有误')
                    return
                }
                if (state.username == '') {
                    Toast("手机号不能为空");
                    return;
                }
                let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
                if (!reg.test(state.username)) {
                    Toast('请输入正确的手机号');
                    return;
                }

                Toast.loading({
                    message: '登陆中，请稍后...',
                    forbidClick: true,
                })
                login({
                    "phone": state.username,
                    "verificationCode": state.verify
                }).then(res => {
                    //console.log(res);
                    Toast.clear()
                    if (res.code == 1) {
                        Toast(res.msg)
                    } else {
                        setLocal('token', res.data.token);
                        setLocal('tokenExpireTime', res.data.tokenExpireTime);
                        if (res.data.isAnswerQuestion) {
                            router.push({path: "/about"})

                            // window.location.href = '/about'
                        } else {
                            router.push({path: "/home"})
                            // window.location.href = '/home'
                        }
                        // window.location.href = '/home'
                    }

                })
                // console.log(data);
                // 需要刷新页面，否则 axios.js 文件里的 token 不会被重置
                // window.location.href = '/index'
            }
            const showNotic=()=>{
                show.value = true;
            }
            //查看是否开启服务器：
            const getServiceState=()=>{
                serviceState({}).then(res=>{
                    //console.log(res);
                    if(res){
                        state.serviceStateShow=true;
                    }else {
                        state.serviceStateShow=false;
                    }
                })
            }
            onMounted(async ()=>{
                show.value = true;
                getServiceState()
                system()

            })
            //长宽判断然后显示图片
            const system = ()=>{
                const width= document.body.clientWidth;
                const height=document.body.clientHeight;
                // console.log(width)
                // console.log(height)
                if(width>height){
                    state.loginPc=true;
                }
            }

            return {
                ...toRefs(state),
                onSubmit,
                verifyRef,
                refreshImg,
                 show,
                showNotic,
                getServiceState,
                system,
            }
        },
        components: {
            vueImgVerify
        }
    }
</script>

<style lang="less" scoped>
    .login {
        background: url("https://sxd.kk.aliyun.com/img/loginBg.png") no-repeat center;
        background-size: 100% 100%;

        .login-body {
            position: absolute;
            top: 58%;
            left: 50%;
            width: 8.7rem;
            transform: translate(-50%, -50%);
            height: 6.1rem;
            background: url("https://sxd.kk.aliyun.com/img/loginForm.png") no-repeat center;
            background-size: 100% 100%;
            /*.loginMaintain{*/
            /*    width: 280px;*/
            /*    height: 160px;*/
            /*    background: url("https://sxd.kk.aliyun.com/img/loginFormNo.png") no-repeat center;*/
            /*    background-size: 280px 160px;*/
            /*}*/

            .inputBox {
                display: flex;
                justify-content: center;
                align-items: center;

                /deep/ .van-field__control {
                    text-align: center;
                    font-size: 18px;
                }

                .loginField {
                    width: 5.8rem;
                    padding: 0;
                    line-height: 1rem;
                    text-align: center;
                }

                .phone {
                    margin-top: 0.9rem;
                }

                .codeImg {
                    width: 2.2rem;
                    margin-top: 10px;
                    height: 0.8rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .refreshImg {
                    width: 0.53rem;
                    height: 0.53rem;
                    margin: 0 0.1rem;
                }

                .code {
                    margin-top: 0.14rem;
                    width: 2.25rem;
                }

                .loginBtn {
                    width: 5.08rem;
                    height: 1.38rem;
                    margin-top: 0.14rem;
                    background: url("https://sxd.kk.aliyun.com/img/loginBtn.png") no-repeat center center;
                    background-size: 100% 100%;
                    text-align: center;
                    font-size: 19px;
                    line-height: 1.1rem;
                    color: #D3C5A3;
                }
            }

        }
        .logoBg{
            position: absolute;
            bottom: 10px;
            width: 100%;
            height: 143px;
            background: url("https://sxd.kk.aliyun.com/img/logo1.png") no-repeat center;
            background-size: 313px 143px;

        }

        /*.van-field  >>> .van-field__control{*/
        /*  text-align: center;*/
        /*}*/

        .verify-bar-area {
            margin-top: 24px;

            .verify-left-bar {
                border-color: #1baeae;
            }

            .verify-move-block {
                background-color: #1baeae;
                color: #fff;
            }
        }

        .verify {
            > div {
                width: 100%;
            }

            display: flex;
            justify-content: center;

            .cerify-code-panel {
                margin-top: 16px;
            }

            .verify-code {
                width: 40% !important;
                float: left !important;
            }

            .verify-code-area {
                float: left !important;
                width: 54% !important;
                margin-left: 14px !important;

                .varify-input-code {
                    width: 90px;
                    height: 38px !important;
                    border: 1px solid #e9e9e9;
                    padding-left: 10px;
                    font-size: 16px;
                }

                .verify-change-area {
                    line-height: 44px;
                }
            }
        }
        .loginDialog{
            .dialogP{
                margin: 8px 0;
                padding: 0 15px;
                font-size: 14px;
                text-indent: 10px;
            }
        }
    }
    .loginPc{
        background: url("https://sxd.kk.aliyun.com/img/loginBgPc.jpg") no-repeat center;
        background-size: 100% 100%;
    }
</style>
